import { initializeApp } from 'firebase/app';
import { getMessaging, getToken as getFCMToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCQIGZMlNXjn9fTQ9kYw1eIzfs9naIRRu8",
  authDomain: "movement-asc.firebaseapp.com",
  projectId: "movement-asc",
  storageBucket: "movement-asc.appspot.com",
  messagingSenderId: "107309450250",
  appId: "1:107309450250:web:7003902eb8289d64710bf3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app);

// Register the service worker for Firebase Cloud Messaging
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js') // Update the path to your service worker script
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

if (Notification.permission !== 'granted') {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
	    console.log('notif allowed');
    } else {
	    console.log('notif not allowed');
    }
  });
}

export const getToken = (setTokenFound) => {
  return getFCMToken(messaging, {vapidKey: 'BAn5ufuLoAZ14jsS0YMAx7Ox5b-dU3eb0BmvjeEfIYFhi2VtWVNDhDsBVbq45tMnkpQpq-XJUoSujye2uJyFGYM'}).then((currentToken) => {
    if (currentToken) {
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}
